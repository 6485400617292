import { JineteService } from '../services/jinete.service';
import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ReportesService } from '../services/reportes.service';
import { ContabilidadService } from '../services/contabilidad.service';
import { ConcursoService } from '../services/concurso.service';
import { PruebaService } from '../services/prueba.service';
import { UsefService } from '../services/usef.service';
import { EntrenadorService } from '../services/entrenador.service';
import { CaballoService } from '../services/caballo.service';
import { PersonaService } from '../services/persona.service';
import { AppService } from './../services/app.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable, first, firstValueFrom } from 'rxjs';
declare var $: any;

@Component({
  selector: 'reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {
  public loading: boolean;
  public progress: number;
  public items: any;
  public USHJAIds:any;
  public token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public filasReporte: any;
  public idConcurso: string | boolean;
  public siglasClubConcurso: string;
  public columnas: any;
  public filasReporteTabla: any;
  public filasInspeccionVeterinaria: Array<any>;
  public filtro: string;
  public categoriaVetcheck: string;
  public tituloReporte: string;
  public tituloReporteEN: string;
  public testPrizes: any;
  public pistas: any;
  public totalInscritos: number;
  public semanaConcurso: number;
  public pruebas: any;
  public reporteInscripciones: boolean;
  public reporteGenerico: boolean;
  public reporteVeterinaria: boolean;
  public backLabels: boolean;
  public classLabels: boolean;
  public balanceByPrizeMoney: boolean;
  public prizeMoneyByEntry: boolean;
  public prizeMoneyByPayee: boolean;
  public unappliedPrizeMoney: boolean;
  public appliedPrizeMoney: boolean;
  public StandarUsefResultsReport: boolean;
  public PrintResultsReport: boolean;
  public entriesByDivision: boolean;
  // public tiempoPista: any;
  public semanas: Array<any>;
  public logoClub: string;
  public header: string;
  public header2: string;
  public footer: string;
  public tiempoEntrePruebas: number;
  public tiempoDesempate: number;
  public baseUrl: string;
  public ipc: number;
  public filasBalanceByPrizeMoney: Array<any>;
  public filasPrizeMoneyByEntry: Array<any>;
  public filasPrizeMoneyByPayee: Array<any>;
  public unappliedPrizeMoneyRows: Array<any>;
  public appliedPrizeMoneyRows: Array<any>;
  public eventos: any[];
  public totalAwarded: number;
  public totalProjected: number;
  public stallsReport: boolean;
  public trainerStalls: any[];
  public totalItems: number;
  public avance: number;


  constructor(
    private usefService: UsefService,
    private router: Router,
    private authService: AuthService,
    private reporteService: ReportesService,
    private contabilidadService: ContabilidadService,
    private concursoService: ConcursoService,
    private pruebaService: PruebaService,
    private entrenadorService: EntrenadorService,
    private caballoService: CaballoService,
    private appService: AppService,
    private personaService: PersonaService
  ) {
    this.loading = false;
    this.progress = 0;
    this.USHJAIds = [];
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.idConcurso = '';
    this.siglasClubConcurso = "chls"; // TODO: Traer dinamicamente
    this.filasReporte = [];
    this.filasReporteTabla = [];
    this.columnas = [];
    this.filasInspeccionVeterinaria = [];
    this.filtro = '';
    this.categoriaVetcheck = '';
    this.tituloReporte = '';
    this.tituloReporteEN = '';
    this.reporteInscripciones = false;
    this.reporteGenerico = false;
    this.reporteVeterinaria = false;
    this.backLabels = false;
    this.classLabels = false;
    this.balanceByPrizeMoney = false;
    this.prizeMoneyByEntry = false;
    this.prizeMoneyByPayee = false;
    this.unappliedPrizeMoney = false;
    this.appliedPrizeMoney = false;
    this.StandarUsefResultsReport = false;
    this.PrintResultsReport = false;
    this.entriesByDivision = false;
    this.testPrizes = [];
    this.pistas = [];
    this.totalInscritos = 0;
    this.semanaConcurso = 0;
    this.baseUrl = window.location.hostname;
    this.semanas = [];
    this.logoClub = '';
    this.header = '';
    this.header2 = '';
    this.footer = '';
    this.tiempoEntrePruebas = 35;
    this.tiempoDesempate = 10;
    this.filasBalanceByPrizeMoney = [];
    this.filasPrizeMoneyByEntry = [];
    this.filasPrizeMoneyByPayee = [];
    this.unappliedPrizeMoneyRows = [];
    this.appliedPrizeMoneyRows = [];
    this.ipc = 0;
    this.eventos = [];
    this.totalAwarded = 0;
    this.totalProjected = 0;
    this.stallsReport = false;
    this.trainerStalls = [];
    this.totalItems = 0;
    this.avance = 0;
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso() && this.privilegios >= 200) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getSemanas();
    this.getConcurso();
    this.usefService.avance.subscribe((avance) => {
      this.avance = avance;
    });
    $("#loadingmodal").on('show.bs.modal', function(e) {
      $(document).on("keydown", function(e) {
        if (e.key === "Escape") {
          return false;
        }
      });
    });
  }


  ngAfterViewInit() {
    /*$('#tiempo1').val(this.tiempoPista['Pista 1']);
    $('#tiempo2').val(this.tiempoPista['Pista 2']);
    $('#tiempo3').val(this.tiempoPista['Pista 3']);
    $('#tiempo4').val(this.tiempoPista['Pista 4']);**/
  }

  private getPruebas() {
    this.pruebaService.getPruebasList(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.pruebas = response.pruebas.filter(prueba => {
            return prueba.premio > 0;
          });
          console.log(response);
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the classes list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getConcurso() {
    this.concursoService.getConcurso(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.logoClub = response.concurso.logoClub;
          this.header = response.concurso.header;
          this.header2 = response.concurso.header2;
          this.footer = response.concurso.footer;
          this.eventos = response.concurso.eventos;
          $('#loader').hide();
        } else {
          console.log(response.message);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "Error in connection, check your connectivity.", 'bottom-right', '#fa5c7c', 'error');
      }
    );
  }

  public getPrizesPerTest(semana) {
    this.reporteService.getInscripciones(this.idConcurso, semana).subscribe(
      response => {
        this.tituloReporte = 'Inscripciones';
        this.tituloReporteEN = 'Entries';
        this.testPrizes = response.map(t => {
          t.panelOpenState = false;

          return t;
        });
        this.getTotalInscritos();
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  private getTotalInscritos() {
    this.totalInscritos = 0;
    this.testPrizes.forEach(
      fila => {
        this.totalInscritos += parseFloat(fila.inscritos);
      }
    );
  }

  private getSemanas() {
    this.concursoService.getSemanas(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.semanas = response.semanas;
        } else {
          console.log(response.message);
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("ERROR!", "Error in connection, check your connectivity.", 'bottom-right', '#fa5c7c', 'error');
      }
    );
  }

  public calcularHora(pista) {
    this.getPrizesPerTest(this.semanaConcurso);
    /*this.tiempoPista['Pista '+pista] = $('#tiempo'+pista).val();
    if(pista == 3){
      this.tiempoPista[' Pista '+pista] = $('#tiempo'+pista).val();
    }*/
  }

  public tiempo(tiempo) {
    //tiempo en segundos
    var horas = Math.floor(tiempo / 3600);
    var minutos = Math.floor((tiempo % 3600) / 60);
    let minutos_real;
    if (minutos < 10) {
      minutos_real = '0' + minutos;
    } else {
      minutos_real = minutos;
    }
    let tiempo_total = `${horas}:${minutos_real}`;
    return tiempo_total
  }

  public horarioInicio(horario, tiempo) {
    let acumulado = horario + tiempo;
    return this.tiempo(acumulado);
  }

  public s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  //* Funciones generales *//
  tableToExcel(table, EmployeeList) {
    let uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
      , base64 = function (s) { return window.btoa(decodeURIComponent(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    if (!table.nodeType) table = document.getElementById(table)
    var ctx = { worksheet: '' || 'Worksheet', table: table.innerHTML }
    window.location.href = uri + base64(format(template, ctx))
  }

  public getNumber(number: number): Array<any> {
    return new Array(number);
  }

  public colorCucarda(cucarda): string {
    if (cucarda) {
      if (!isNaN(parseInt(cucarda))) {
        cucarda = parseInt(cucarda);
        if (cucarda >= 1 && cucarda <= 399) {
          return 'bg-red';
        } else if (cucarda >= 400 && cucarda <= 999) {
          return 'bg-white'; //Bucar la manera cambiar el texto a negro
        } else if (cucarda >= 1000 && cucarda <= 1150) {
          return 'bg-green';
        } else {
          return 'bg-gray'; //Gris default
        }
      } else {
        if (cucarda.charAt(0).toLocaleLowerCase() == 'a' && cucarda.indexOf('-') > -1) {
          const array = cucarda.split('-');
          if (array.length == 2) {
            if (!isNaN(parseInt(array[1]))) {
              //Ingate: 50
              if (parseInt(array[1]) >= 1 && parseInt(array[1]) <= 50) {
                return 'blue';
              }
            }
          }
          return '#dcdcdc'; //Gris default
        } else {
          return '#dcdcdc'; //Gris default
        }
      }
    }

    return '#dcdcdc'; //Gris default
  }

  public isNumber(e) { return typeof e === 'number' }

  public imprimir(filtro) {
    this.filtro = filtro;
    if (filtro == "i") {
      this.categoriaVetcheck = `Caballos CSI 3*`;
    } else if (filtro) {
      this.categoriaVetcheck = `Caballos Jóvenes ${filtro} años`;
    } else {
      this.categoriaVetcheck = '';
    }
    console.log('filtro ' + filtro);

    setTimeout(() => {
      window.print();
    }, 500);
  }

  public contarCaballos(): number {
    return this.filasInspeccionVeterinaria.filter(caballo => {
      return !caballo.hidden;
    }).length;
  }

  public tiempoPista(idModalidad: string): number {
    let tiempo = 2;

    switch (idModalidad) {
      case '3': //Desempate 238.2.2
        tiempo = 2.3;
        break;
      case '4': //Dos fases 274.5.3
        tiempo = 1.8;
        break;
    }

    return parseFloat(tiempo.toFixed(2));
  }

  public tiempoTotalPista(pista): number {
    let suma = pista.pistas.map(prueba => {
      return this.cortarDecimales(this.tiempoPista(prueba.idModalidad) * prueba.cantidad);
    }).reduce((a, b) => {
      return a + b;
    }) * 60;

    return ((pista.pistas.length - 1) * this.tiempoEntrePruebas * 60) + suma;
  }

  public cortarDecimales(n: number): number {
    return parseFloat(n.toFixed(2));
  }

  /******************FUNCIONES DE REPORTES******************/
  public getMultasPorResponsableExcel() {
    this.mostrarReporteSeleccionado('excel');
    this.reporteService.getMultasPorResponsable(this.idConcurso).subscribe(
      response => {
        console.log(response);
        this.filasReporte = response;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };
        wb.SheetNames.push('Multas por responsable');
        wb.Sheets['Multas por responsable'] = ws;
        var wbout = XLSX.write(wb, wopts);
        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Resumen_" + this.nombreConcurso + ".xlsx");
      },
      error => {
        console.log(error);
      }
    );
  }

  public getMultasPorResponsableTabla() {
    this.tituloReporte = '';
    this.tituloReporteEN = '';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getMultasPorResponsable(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response;
        /*
          convierte el campo 'MULTA' en flotante para que el PIPE de angular le agregue el
          formato de moneda.
        */
        let i = 0;
        this.filasReporteTabla.forEach(
          fila => {
            this.filasReporteTabla[i].multa = parseFloat(fila.multa);
            i++;
          }
        );
        this.columnas = Object.keys(this.filasReporteTabla[0]);
      },
      error => {
        console.log(error);
      }
    );
  }

  public getInspeccionVeterinaria(semana) {
    $('#loader').show();
    this.mostrarReporteSeleccionado('reporteVeterinaria');
    this.reporteService.getInspeccionVeterinaria(this.idConcurso, semana).subscribe(
      response => {
        if (!response.error) {
          this.filasInspeccionVeterinaria = response.caballos;
          $('#loader').hide();
        } else {
          $.gritter.add({ title: "Error", text: response.message, class_name: "color danger" });
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.gritter.add({ title: "Error", text: "No ha sido posible obtener el listado de caballos..", class_name: "color danger" });
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getRobinsReport(): void {
    $('#loader').show();
    this.tituloReporte = 'Robin\'s Report';
    this.tituloReporteEN = 'Robin\'s Report';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getRobinsReport(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.conceptos;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  // Master List
  public getMasterListTabla(evento = '') {
    $('#loader').show();
    this.tituloReporte = 'Master List';
    this.tituloReporteEN = 'Master List';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getMasterList(this.idConcurso, evento).subscribe(
      response => {
        this.filasReporteTabla = response.horses;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getMasterListExcel(evento = '') {
    $('#loader').show();
    this.mostrarReporteSeleccionado('excel');
    this.reporteService.getMasterList(this.idConcurso, evento).subscribe(
      response => {
        this.filasReporte = response.horses;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.horses);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        wb.SheetNames.push('Sheet 1');
        wb.Sheets['Sheet 1'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "MasterList_" + this.nombreConcurso + ".xlsx");
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  // Back Labels
  public getBackLabels() {
    $('#loader').show();
    this.mostrarReporteSeleccionado('backLabels');
    this.reporteService.getMasterList(this.idConcurso, '').subscribe(
      response => {
        this.filasReporteTabla = response.horses;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getFilteredBackLabels() {
    $('#loader').show();
    this.mostrarReporteSeleccionado('backLabels');
    this.reporteService.getFilteredMasterList(this.idConcurso, '').subscribe(
      response => {
        this.filasReporteTabla = response.horses;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  //Class Labels
  public getClassLabels() {
    $('#loader').show();
    this.mostrarReporteSeleccionado('classLabels');
    this.reporteService.getClassLabels(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.filasReporteTabla = response.pruebas;
          this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
          $('#loader').hide();
        } else {
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getUsefResults() {
    $('#loader').show();
    this.mostrarReporteSeleccionado('excel');
    this.reporteService.getUsefResults(this.idConcurso).subscribe(
      response => {
        console.log(response);
        this.filasReporte = response;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response);
        var wopts: XLSX.WritingOptions = { bookType: 'csv', bookSST: false, type: 'binary' };

        wb.SheetNames.push('USEF Results');
        wb.Sheets['USEF Results'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "USEF_" + this.nombreConcurso + "_Results.csv");
        $('#loader').hide();
      },
      error => {
        console.log('Error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getCuentaEntrenadores() {
    $('#loader').show();
    this.tituloReporte = 'Saldo de entrenadores';
    this.tituloReporteEN = 'Trainers Balance';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.entrenadorService.getInfoResumen(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          const entrenadores = [];
          $.map(response.entrenadores, (entrenador) => {
            const e = [];
            // if (entrenador.total != 0) {
            e['Name'] = entrenador.nombre;
            e['Trainer balance'] = entrenador.saldo;
            let entries = '';
            for (const t of entrenador.entries) {
              entries += t.entry + ' - ' + t.horse + ':   $' + Intl.NumberFormat().format(t.saldo) + '.<br>';
            }
            e['Entries'] = entries;
            e['Total'] = entrenador.total;
            entrenadores.push(e);
            // }
            console.log(e);
            return e;
          });
          this.filasReporteTabla = entrenadores;
          this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
          $('#loader').hide();
        } else {
          $('#loader').hide();
          $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error');
          console.error(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send('Error', 'It was not possible to consult the trainers list.', 'bottom-right', '#fa5c7c', 'error');
        console.error(error);
      }
    );
  }

  public getCargosPorResponsablesExcel() {
    this.mostrarReporteSeleccionado('excel');
    this.reporteService.getCargosPorResponsables(this.idConcurso).subscribe(
      response => {
        const responsables = $.map(response.responsables, (responsable) => {
          return responsable;
        });

        let cargosResponsable = [];

        for (const responsable of responsables) {
          cargosResponsable.push(responsable);
        }

        this.filasReporte = cargosResponsable;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(cargosResponsable);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        console.log(ws);

        wb.SheetNames.push('Detalle de cargos');
        wb.Sheets['Detalle de cargos'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Resumen_" + this.nombreConcurso + ".xlsx");
      },
      error => {
        console.log(error);
      }
    );
  }

  public getCargosPorResponsables() {
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getCargosPorResponsables(this.idConcurso).subscribe(
      response => {
        const responsables = $.map(response.responsables, (responsable) => {
          return responsable;
        });

        let cargosResponsable = [];

        for (const responsable of responsables) {
          cargosResponsable.push(responsable);
        }

        this.filasReporteTabla = cargosResponsable;
        this.columnas = Object.keys(this.filasReporteTabla[0]);
      },
      error => {
        console.log(error);
      }
    );
  }

  public getMerchInventory() {
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getMerchInventory(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.products;
        this.columnas = Object.keys(response.products[0]);
      },
      error => {
        console.log(error);
      }
    );
  }

  public getMerchInventoryExcel() {
    this.mostrarReporteSeleccionado('excel');
    this.reporteService.getMerchInventory(this.idConcurso).subscribe(
      response => {
        this.filasReporte = response.products;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.products);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        console.log(ws);

        wb.SheetNames.push('Merch Inventory');
        wb.Sheets['Merch Inventory'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "MerchInventory.xlsx");
      },
      error => {
        console.log(error);
      }
    );
  }

  public mostrarInscripciones(semana) {
    $('#loader').show();
    this.semanaConcurso = semana;
    this.getPrizesPerTest(semana);
    this.mostrarReporteSeleccionado('reporteInscripciones');
  }

  public getInscripciones(semana) {
    this.mostrarReporteSeleccionado('excel');
    this.reporteService.getInscripciones(this.idConcurso, semana).subscribe(
      response => {
        this.filasReporte = response;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        wb.SheetNames.push('Inscripciones');
        wb.Sheets['Inscripciones'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Inscripciones " + this.nombreConcurso + ".xlsx");
      },
      error => {
        console.log(error);
      }
    );
  }

  public getInscripcionesTabla(semana) {
    this.tituloReporte = 'Inscripciones';
    this.tituloReporteEN = 'Entries';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getInscripciones(this.idConcurso, semana).subscribe(
      response => {
        this.filasReporteTabla = response;
        this.columnas = Object.keys(this.filasReporteTabla[0]);
      },
      error => {
        console.log(error);
      }
    );
  }

  public getJinetesSinFemExcel() {
    this.mostrarReporteSeleccionado('excel');
    this.reporteService.getJinetesSinFem(this.idConcurso).subscribe(
      response => {
        this.filasReporte = response.jinetes;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.jinetes);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        wb.SheetNames.push('Sheet 1');
        wb.Sheets['Sheet 1'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Jinetes_sin_FEM_" + this.nombreConcurso + ".xlsx");
      },
      error => {
        console.log(error);
      }
    );
  }

  public getJinetesSinFemTabla() {
    this.tituloReporte = 'Jinetes sin FEM';
    this.tituloReporteEN = 'Riders without FEM Registration';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getJinetesSinFem(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.jinetes;
        this.columnas = Object.keys(this.filasReporteTabla[0]);
      },
      error => {
        console.log(error);
      }
    );
  }

  getMissingTaxIDs() {
    console.log('Missing Tax IDs');
    this.tituloReporte = 'Missing Tax ID';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getMissingTaxId(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.payees;
        this.columnas = Object.keys(this.filasReporteTabla[0]);
      },
      error => {
        console.log(error);
      }
    );
  }

  getCreditCardPayments() {
    console.log('Credit Card Payments');
    this.tituloReporte = 'Credit Card Payments (Bank Transactions)';
    this.tituloReporteEN = 'Credit Card Payments (Bank Transactions)';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getCreditCardPayments(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.payments;
        this.columnas = Object.keys(this.filasReporteTabla[0]);
      },
      error => {
        console.log(error);
      }
    );
  }

  getCreditCardPaymentsTransactions() {
    console.log('Credit Card Payments');
    this.tituloReporte = 'Credit Card Payments (Office Transactions)';
    this.tituloReporteEN = 'Credit Card Payments (Office Transactions)';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getCreditCardPaymentsTransactions(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.payments;
        this.columnas = Object.keys(this.filasReporteTabla[0]);
      },
      error => {
        console.log(error);
      }
    );
  }

  public getCaballosSinFemExcel() {
    this.mostrarReporteSeleccionado('excel');
    this.reporteService.getCaballosSinFem(this.idConcurso).subscribe(
      response => {
        this.filasReporte = response.caballos;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.caballos);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        wb.SheetNames.push('Sheet 1');
        wb.Sheets['Sheet 1'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Caballos_sin_FEM_" + this.nombreConcurso + ".xlsx");
      },
      error => {
        console.log(error);
      }
    );
  }

  public getCaballosSinFemTabla() {
    this.tituloReporte = 'Caballos sin FEM';
    this.tituloReporteEN = 'Horses without FEM Registration';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getCaballosSinFem(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.caballos;
        this.columnas = Object.keys(this.filasReporteTabla[0]);
      },
      error => {
        console.log(error);
      }
    );
  }

  public getCaballosTabla() {
    $('#loader').show();
    this.tituloReporte = 'Caballos inscritos en el Concurso';
    this.tituloReporteEN = 'Horses in Show';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getCaballos(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.caballos;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getCaballosSinPruebasTabla() {
    $('#loader').show();
    this.tituloReporte = 'Caballos no inscritos';
    this.tituloReporteEN = 'Horses without classes';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getCaballosSinPruebas(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.caballos;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getJinetesTabla(alfabetico = false) {
    $('#loader').show();
    this.tituloReporte = 'Jinetes inscritos en el Concurso';
    this.tituloReporteEN = 'Riders in Show';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getJinetes(this.idConcurso, alfabetico).subscribe(
      response => {
        this.filasReporteTabla = response.jinetes;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getDirectory(){
    $('#loader').show();
    this.tituloReporteEN = 'People Directory';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getDirectory(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.data;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getSeriesNumbers(showIds){
    $('#loader').show();
    this.tituloReporteEN = 'Numbers Report';
    this.mostrarReporteSeleccionado('reporteGenerico');

    this.reporteService.getSeriesNumbers(showIds).subscribe(
      (response: any) => {
        this.filasReporteTabla = response.data;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getFeedOrdersSummary(){
    //$('#loader').show();
    this.tituloReporteEN = 'Feed Orders Summary';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getFeedOrdersSummary(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.data;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

public async downloadFeedOrdersSummary(){
  const { data, error } = await firstValueFrom(this.reporteService.getFeedOrdersSummary(this.idConcurso)).catch(error => ({ error }));
  if(error){
    console.log(error);
    $.NotificationApp.send('Error', error.message, 'bottom-right', '#fa5c7c', 'error');
    return;
  }
  this.appService.downloadAsExcel({ 'Sheet1': data }, 'Feed Orders Items Report');
}

  public downloadDirectory(){
    this.mostrarReporteSeleccionado('excel');
    this.reporteService.getDirectory(this.idConcurso).subscribe(
      response => {
        this.filasReporte = response.data;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.data);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        wb.SheetNames.push('Sheet 1');
        wb.Sheets['Sheet 1'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "People Directory " + this.nombreConcurso + ".xlsx");
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }


  public getPasesDeSalidaTabla() {
    this.tituloReporte = 'Pases de salida';
    this.tituloReporteEN = 'Exit Passes';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getPasesDeSalida(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.pases;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
      },
      error => {
        console.log(error);
      }
    );
  }

  // Reporte de Caballos USEF Suspendidos
  public getSuspendedHorsesTabla() {
    $('#loader').show();
    this.tituloReporte = 'Caballos suspendidos';
    this.tituloReporteEN = 'Suspended horses';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getLiveSuspendedHorses(this.idConcurso).subscribe(
      response => {
        console.log(response)
        this.filasReporteTabla = response.horses;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
        $('#loader').hide();
        $('#loader-usef').hide();
      },
      error => {
        $('#loader').hide();
        $('#loader-usef').hide();
        console.log(error);
      }
    );
  }

  public getSuspendedHorsesExcel() {
    $('#loader').show();
    this.mostrarReporteSeleccionado('excel');
    this.reporteService.getLiveSuspendedHorses(this.idConcurso).subscribe(
      response => {
        this.filasReporte = response.horses;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.horses);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        wb.SheetNames.push('Sheet 1');
        wb.Sheets['Sheet 1'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Suspended_horses_" + this.nombreConcurso + ".xlsx");
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }


  // Dueños Suspendidos
  public getSuspendedOwnersTabla() {
    this.tituloReporte = 'Dueños suspendidos';
    this.tituloReporteEN = 'Suspended Owners';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getSuspendedOwners(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.jinetes;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
      },
      error => {
        console.log(error);
      }
    );
  }

  public getSuspendedOwnersExcel() {
    this.mostrarReporteSeleccionado('getSuspendedOwnersExcel');
    this.reporteService.getSuspendedOwners(this.idConcurso).subscribe(
      response => {
        this.filasReporte = response.jinetes;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.jinetes);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        wb.SheetNames.push('Sheet 1');
        wb.Sheets['Sheet 1'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Suspended_owners_" + this.nombreConcurso + ".xlsx");
      },
      error => {
        console.log(error);
      }
    );
  }

  // Entrenadores Suspendidos
  public getSuspendedTrainersTabla() {
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.tituloReporte = 'Entrenadores Suspendidos';
    this.tituloReporteEN = 'Suspended Trainers';
    this.reporteService.getSuspendedTrainers(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.jinetes;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
      },
      error => {
        console.log(error);
      }
    );
  }

  public getSuspendedTrainersExcel() {
    this.mostrarReporteSeleccionado('getSuspendedTrainersExcel');
    this.reporteService.getSuspendedTrainers(this.idConcurso).subscribe(
      response => {
        this.filasReporte = response.jinetes;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.jinetes);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        wb.SheetNames.push('Sheet 1');
        wb.Sheets['Sheet 1'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Suspended_trainers_" + this.nombreConcurso + ".xlsx");
      },
      error => {
        console.log(error);
      }
    );
  }

  // Jinetes Suspendidos
  public getSuspendedPeopleTabla() {
    $('#loader').show();
    this.tituloReporte = 'Jinetes suspendidos';
    this.tituloReporteEN = 'Suspended People / Orgs';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getLiveSuspendedPeople(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.filasReporteTabla = response.suspensions;
          this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
          $('#loader').hide();
          $('#loader-usef').hide();
        } else {
          $('#loader').hide();
          $('#loader-usef').hide();
          $.NotificationApp.send("ERROR!", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $('#loader-usef').hide();
        $.NotificationApp.send("ERROR!", "Error in connection, check your connectivity.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getSuspendedRidersExcel() {
    $('#loader').show();
    this.reporteInscripciones = false;
    this.mostrarReporteSeleccionado('getSuspendedRidersExcel');
    this.reporteService.getLiveSuspendedPeople(this.idConcurso).subscribe(
      response => {
        this.filasReporte = response.suspensions;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.suspensions);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        wb.SheetNames.push('Sheet 1');
        wb.Sheets['Sheet 1'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Suspended_riders_" + this.nombreConcurso + ".xlsx");
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getMembershipPeopleTabla() {
    $('#loader').show();
    this.tituloReporte = 'USEF Membership People';
    this.tituloReporteEN = 'USEF Membership People';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getMembershipPeople(this.idConcurso).subscribe(
      async response => {
        if (!response.error) {

          let sortedResponse = response.data.sort((a, b) => {
            if (a.lastname_1 < b.lastname_1) {
              return -1;
            }
            if (a.lastname_1 > b.lastname_1) {
              return 1;
            }
            return
          });

          let personas = response.data.map((person) => {
            let persona: any = {};
            persona['#'] = person.fei; // Not actually the FEI number but the backNumber
            persona['name'] = person.name + ' ' + person.lastname_1;
            persona['USEF ID'] = person.usef_id;
            persona['USHJA ID'] = person.ushja_id;
            persona['USEF Suspension Reason'] = person.usef_suspension_reason;
            persona['USHJA Suspension Reason'] = person.ushja_suspension_reason;
            return persona;
          });

          this.columnas = response.data.length > 0 ? Object.keys(personas[0]) : [];
          this.filasReporteTabla = personas;




          $('#loader').hide();
          $('#loader-usef').hide();
        } else {
          $('#loader').hide();
          $('#loader-usef').hide();
          $.NotificationApp.send("ERROR!", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $('#loader-usef').hide();
        $.NotificationApp.send("ERROR!", "Error in connection, check your connectivity.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getMembershipHorsesTabla() {
    $('#loader').show();
    this.tituloReporte = 'USEF Membership Horses';
    this.tituloReporteEN = 'USEF Membership Horses';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getMembershipHorses(this.idConcurso).subscribe(
      async response => {
        if (!response.error) {
          $('#loader').hide();
          $('#loader-usef').hide();
          this.columnas = response.suspensions.length > 0 ? Object.keys(response.suspensions[0]) : [];
          this.columnas.push('USHJA Status');
          const suspensions = response.suspensions;
          const chunkSize = 50;
          const numChunks = Math.ceil(suspensions.length / chunkSize);

          for (let i = 0; i < numChunks; i++) {
            const chunk = suspensions.slice(i * chunkSize, (i + 1) * chunkSize);
            const promises = chunk.map(async (sus, index) => {
              if (sus['USEF#'] !== '' && !isNaN(sus['USEF#'])) {
                const { data, error } = await this.usefService.getUSHJAhorseSuspensionInfo(parseInt(sus['USEF#']));
                if(error) return;
                if(data){
                  data['USHJA Status'] = (data['ushja_suspension_status'] || '')
                } else {
                  data['USHJA Status'] = 'No results'
                }
                console.log(data);
              }
            });
            await Promise.all(promises);
          }
          this.filasReporteTabla = response.suspensions;
          $('#loader').hide();
          $('#loader-usef').hide();
        } else {
          $('#loader').hide();
          $('#loader-usef').hide();
          $.NotificationApp.send("ERROR!", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $('#loader-usef').hide();
        $.NotificationApp.send("ERROR!", "Error in connection, check your connectivity.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  // Reporte de Premios
  public getPremiosTabla() {
    $('#loader').show();
    this.tituloReporte = 'Premios';
    this.tituloReporteEN = 'Prizes';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getPremios(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.premios;
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getPremiosExcel() {
    $('#loader').show();
    this.mostrarReporteSeleccionado('getPremiosExcel');
    this.reporteService.getPremios(this.idConcurso).subscribe(
      response => {
        this.filasReporte = response.premios;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.premios);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        wb.SheetNames.push('Sheet 1');
        wb.Sheets['Sheet 1'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Prizes_" + this.nombreConcurso + ".xlsx");
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  //Credit Balance Report by Prize Money Recipient
  public getBalanceByPrizeMoney() {
    $('#loader').show();
    this.mostrarReporteSeleccionado('balanceByPrizeMoney');
    this.reporteService.getBalanceByPrizeMoney(this.idConcurso).subscribe(
      response => {
        this.filasBalanceByPrizeMoney = response.premios;
        console.log(this.filasBalanceByPrizeMoney);
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  //Prize Money - By Entry
  public getPrizeMoneyByEntry() {
    $('#loader').show();
    this.mostrarReporteSeleccionado('prizeMoneyByEntry');
    this.reporteService.getPrizeMoneyByEntry(this.idConcurso).subscribe(
      response => {
        this.filasPrizeMoneyByEntry = response.premios;
        console.log(this.filasPrizeMoneyByEntry);
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  //Prize Money - By Payee
  public getPrizeMoneyByPayee() {
    $('#loader').show();
    this.mostrarReporteSeleccionado('prizeMoneyByPayee');
    this.reporteService.getPrizeMoneyByPayee(this.idConcurso).subscribe(
      response => {
        this.filasPrizeMoneyByPayee = response.premios;
        console.log(this.filasPrizeMoneyByPayee);
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  // Payees with mailing addresses and prizes
  public getPayeesWithMailingAddresses() {
    $('#loader').show();
    this.tituloReporte = `Payees with Mailing Addresses`;
    this.tituloReporteEN = `Payees with Mailing Addresses`;
    this.mostrarReporteSeleccionado('reporteGenerico');
      this.reporteService.getPayeesWithMailingAddress(this.idConcurso).subscribe(
        response => {
          this.filasReporteTabla = response.payees;
          this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
          $('#loader').hide();
        },
        error => {
          console.log(error);
        $('#loader').hide();
        }
      );
  }

  // Payees with mailing addresses and prizes (spreadsheet download)
  public downloadPayeesWithMailingAddresses() {
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getPayeesWithMailingAddress(this.idConcurso).subscribe(
      response => {
        this.filasReporte = response.payees;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.payees);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        wb.SheetNames.push('Sheet 1');
        wb.Sheets['Sheet 1'] = ws;
        var wbout = XLSX.write(wb, wopts);
        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "PayeeMailingAddresses_" + this.nombreConcurso + ".xlsx");
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );

  }

  public getPrizesByClass(type?: string) {
    $('#loader').show();
    console.log(type);
    this.tituloReporte = `Prizes by ${type||''} class`;
    this.tituloReporteEN = `Prizes by ${type||''} class`;
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getPrizesByClass(this.idConcurso, type).subscribe(
      response => {
        this.filasReporteTabla = response.premios;
        this.columnas = Object.keys(this.filasReporteTabla[0]);
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public async getUnappliedPrizeMoney(download: boolean = false) {
    $('#loader').show();
    const { error, premios } = await firstValueFrom(this.reporteService.getUnappliedPrizeMoney(this.idConcurso)).then(r => ({ ...r, error: r?.message??false })).catch(error => ({ error: error?.message??'' }));
    if(error){
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      $('#loader').hide();
      return;
    }
    this.tituloReporteEN = 'Unapplied Prize Money';
    if(download){
      this.appService.downloadAsExcel({ 'Sheet1': premios }, this.tituloReporteEN);
      $('#loader').hide();
      return;
    }
    this.mostrarReporteSeleccionado('unappliedPrizeMoney');
    this.unappliedPrizeMoneyRows = premios??[];
    $('#loader').hide();

  }

  public async getAppliedPrizeMoney(download: boolean = false) {
    $('#loader').show();
    const { error, premios } = await firstValueFrom(this.reporteService.getAppliedPrizeMoney(this.idConcurso)).then(r => ({ ...r, error: r?.message??false })).catch(error => ({ error: error?.message??'' }));
    if(error){
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error');
      $('#loader').hide();
      return;
    }
    this.tituloReporteEN = 'Applied Prize Money';
    if(download){
      this.appService.downloadAsExcel({ 'Sheet1': premios }, this.tituloReporteEN);
      $('#loader').hide();
      return;
    }
    this.appliedPrizeMoneyRows = premios??[];
    this.mostrarReporteSeleccionado('appliedPrizeMoney');
    $('#loader').hide();
  }

  public getStandarUsefResults() {
    $('#loader').show();
    this.reporteService.getStandardUsefResults(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.pruebas;
        this.totalAwarded = response.totalAwarded;
        this.totalProjected = response.totalProjected;
        console.log(response);
        this.columnas = Object.keys(this.filasReporteTabla[0]['resultados'][0]);
        this.mostrarReporteSeleccionado('StandarUsefResultsReport');
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  async getPrintableResults() {
    this.filasReporteTabla = [];
    this.totalAwarded = 0;
    this.totalProjected = 0;
    $('#loader').show();
    let classes: any = [];
    try {
      classes = await this.reporteService.getClassesForPrintable(this.idConcurso).toPromise();
    } catch (error) {
      console.log(error);
    }

    console.log(classes);
    const batchSize = 50;
    const totalClasses = classes.data.length;
    const numBatches = Math.ceil(totalClasses / batchSize);

    for (let i = 0; i < numBatches; i++) {
      const start = i * batchSize;
      const end = Math.min((i + 1) * batchSize, totalClasses);
      const batch = classes.data.slice(start, end);

      try {
        const response = await this.reporteService.getPrintable(batch).toPromise();
        this.filasReporteTabla.push(...response.pruebas);
        console.log(response);
        this.totalAwarded += response.totalAwarded;
        this.totalProjected += response.totalProjected;
        console.log(response);
      } catch (error) {
        console.log(error);
        $('#loader').hide();
        return;
      }
    }

    console.log(this.filasReporteTabla);
    if (this.filasReporteTabla.length > 0) {
      this.columnas = Object.keys(this.filasReporteTabla[0]['resultados'][0]);
      this.mostrarReporteSeleccionado('PrintResultsReport');
    }
    $('#loader').hide();
  }

  public getStallsReport() {
    $('#loader').show();
    this.mostrarReporteSeleccionado('stallsReport');
    this.reporteService.getStallsReport(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          const conceptos = response.conceptos.sort();
          this.trainerStalls = response.entrenadores.map((trainer) => ({
            ...trainer,
            conceptos: conceptos.map((concepto) => ({ name: concepto, total: trainer[concepto]||0}))
          }));
          console.log(this.trainerStalls);
          $('#loader').hide();
        } else {
          $.gritter.add({ title: "Error", text: response.message, class_name: "color danger" });
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $.gritter.add({ title: "Error", text: "No ha sido posible obtener el listado de caballos..", class_name: "color danger" });
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getBalancePerDivisionReport(tipo_reporte) {
    $('#loader').show();
    //this.mostrarReporteSeleccionado('excel');
    this.reporteService.getBalancePerDivisionReport(this.idConcurso, tipo_reporte).subscribe(
      response => {
        console.log(response)
        this.filasReporte = response;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        wb.SheetNames.push('USEF Results');
        wb.Sheets['USEF Results'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "USEF_" + this.nombreConcurso + "_Results.xlsx");
        $('#loader').hide();
      },
      error => {
        console.log('Error');
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  /**
   * @param parametro STRING: Se para el nombre de la variable booleana que habilita el reporte.
   */
  public mostrarReporteSeleccionado(parametro: string) {
    this.ipc = 0;
    this.reporteGenerico = false;
    this.reporteInscripciones = false;
    this.reporteVeterinaria = false;
    this.backLabels = false;
    this.classLabels = false;
    this.balanceByPrizeMoney = false;
    this.prizeMoneyByEntry = false;
    this.prizeMoneyByPayee = false;
    this.unappliedPrizeMoney = false;
    this.appliedPrizeMoney = false;
    this.StandarUsefResultsReport = false;
    this.PrintResultsReport = false;
    this.entriesByDivision = false;
    this.stallsReport = false;
    if (parametro == 'reporteGenerico') {
      this.reporteGenerico = true;
    } else if (parametro == 'reporteInscripciones') {
      this.reporteInscripciones = true;
    } else if (parametro == 'reporteVeterinaria') {
      this.reporteVeterinaria = true;
    } else if (parametro == 'backLabels') {
      this.backLabels = true;
    } else if (parametro == 'classLabels') {
      this.classLabels = true;
    } else if (parametro == 'balanceByPrizeMoney') {
      this.balanceByPrizeMoney = true;
    } else if (parametro == 'prizeMoneyByEntry') {
      this.prizeMoneyByEntry = true;
    } else if (parametro == 'prizeMoneyByPayee') {
      this.prizeMoneyByPayee = true;
    } else if (parametro == 'StandarUsefResultsReport') {
      this.StandarUsefResultsReport = true;
    } else if (parametro == 'PrintResultsReport') {
      this.PrintResultsReport = true;
    } else if (parametro == 'unappliedPrizeMoney') {
      this.unappliedPrizeMoney = true
    } else if (parametro == 'appliedPrizeMoney') {
      this.appliedPrizeMoney = true
    } else if (parametro == 'entriesByDivision') {
      this.entriesByDivision = true
    } else if (parametro == 'stallsReport') {
      this.stallsReport = true
    } else {
      console.log("No hay una variable que permita mostrar el reporte seleccionado o se descargo algun Excel");
    }
  }

  public updateSuspensionsStatusCaballos() {
    $('#loader-usef').show();
    this.caballoService.updateSuspensionsStatus(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.getSuspendedHorsesTabla();
          $.NotificationApp.send("Success", "The suspension status list has been updated successfully.", 'bottom-right', '#0acf97', 'success');
        } else {
          $('#loader-usef').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader-usef').hide();
        $.NotificationApp.send("Error", "It has not been possible to update the suspension status list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public updateSuspensionsStatusPersonas() {
    $('#loader-usef').show();
    this.personaService.updateSuspensionsStatus(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.getSuspendedPeopleTabla();
          $.NotificationApp.send("Success", "The suspension status list has been updated successfully.", 'bottom-right', '#0acf97', 'success');
        } else {
          $('#loader-usef').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader-usef').hide();
        $.NotificationApp.send("Error", "It has not been possible to update the suspension status list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public updateUsefPeople() {
    $('#loader-usef').show();
    this.personaService.updateUsefPeople(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          console.log('Verified');
          $('#loader-usef').hide();
          $.NotificationApp.send("Success", "The riders list has been updated successfully.", 'bottom-right', '#0acf97', 'success');
        } else {
          $('#loader-usef').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response);
        }
      },
      error => {
        $('#loader-usef').hide();
        $.NotificationApp.send("Error", "It has not been possible to update the riders list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public updateUsefHorses() {
    $('#loader-usef').show();
    this.caballoService.updateUsefHorses(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          $('#loader-usef').hide();
          console.log('Verified');
          $.NotificationApp.send("Success", "The horses list has been updated successfully.", 'bottom-right', '#0acf97', 'success');
        } else {
          $('#loader-usef').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response);
        }
      },
      error => {
        $('#loader-usef').hide();
        $.NotificationApp.send("Error", "It has not been possible to update the horses list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public getEmailJinetes(): void {
    this.mostrarReporteSeleccionado('excel');
    this.reporteService.getEmailJinetes(this.idConcurso).subscribe(
      response => {
        this.filasReporte = response.jinetes;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.jinetes);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };
        wb.SheetNames.push('Multas por responsable');
        wb.Sheets['Multas por responsable'] = ws;
        var wbout = XLSX.write(wb, wopts);
        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Emails_" + this.nombreConcurso + ".xlsx");
      },
      error => {
        console.log(error);
      }
    );
  }

  public async getQuickbooks() {
    this.mostrarReporteSeleccionado('excel');
    this.reporteService.getQuickbooks(this.idConcurso).subscribe(
      response => {
        console.log(response);
        this.filasReporte = response.quickbooks;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.quickbooks);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };
        wb.SheetNames.push('Sheet 1');
        wb.Sheets['Sheet 1'] = ws;
        var wbout = XLSX.write(wb, wopts);
        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Quickbooks_" + this.nombreConcurso + ".xlsx");

        this.filasReporte = response.vendors;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.vendors);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };
        wb.SheetNames.push('Sheet 1');
        wb.Sheets['Sheet 1'] = ws;
        var wbout = XLSX.write(wb, wopts);
        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Vendors_" + this.nombreConcurso + ".xlsx");
      },
      error => {
        console.log(error);
      }
    );
  }

  public getEntriesDivisionType() {
    this.tituloReporte = `Entries by division charged by`;
    this.tituloReporteEN = `Entries by division charged by`;
    this.mostrarReporteSeleccionado('reporteGenerico');
    console.log(this.nombreConcurso);
    this.reporteService.getEntriesDivisionType(this.idConcurso).subscribe(
      response => {
        console.log(response);
        this.filasReporteTabla = response.divisions;
        /*
          convierte el campo 'MULTA' en flotante para que el PIPE de angular le agregue el
          formato de moneda.
        */
        let i = 0;
        /*this.filasReporteTabla.forEach(
          fila => {
            this.filasReporteTabla[i].multa = parseFloat(fila.multa);
            i++;
          }
        );*/
        this.columnas = this.filasReporteTabla.length > 0 ? Object.keys(this.filasReporteTabla[0]) : [];
      },
      error => {
        console.log(error);
      }
    );
  }

  public getChecks() {
    console.log('Get checks');
    $('#loader').show();
    this.tituloReporte = 'Checks List';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getChecks(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.checks;
        console.log(this.filasReporteTabla)
        this.columnas = Object.keys(this.filasReporteTabla[0]);
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getCreditCardPaymentsPerDay() {
    console.log('Get payments summary');
    $('#loader').show();
    this.tituloReporteEN = 'CC Payments per day';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getCreditCardPaymentsPerDay(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.payments;
        this.columnas = Object.keys(this.filasReporteTabla[0]);
        console.log(this.filasReporteTabla, this.columnas)
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getChecksExcel() {
    $('#loader').show();
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getChecks(this.idConcurso).subscribe(
      response => {
        this.filasReporte = response.checks;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.checks);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };

        wb.SheetNames.push('Sheet 1');
        wb.Sheets['Sheet 1'] = ws;

        var wbout = XLSX.write(wb, wopts);

        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Outgoing checks " + this.nombreConcurso + ".xlsx");
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
  }

  public getTrainers() {
    console.log('Get checks');
    $('#loader').show();
    this.tituloReporte = 'Trainer Phone Info';
    this.mostrarReporteSeleccionado('reporteGenerico');
    this.reporteService.getTrainers(this.idConcurso).subscribe(
      response => {
        this.filasReporteTabla = response.trainers;
        this.columnas = Object.keys(this.filasReporteTabla[0]);
        $('#loader').hide();
      },
      error => {
        console.log(error);
        $('#loader').hide();
      }
    );
    this.reporteService.getTrainers(this.idConcurso).subscribe(
      response => {
        console.log(response);
        this.filasReporte = response.trainers;
        var wb = { SheetNames: [], Sheets: {} };
        var ws = XLSX.utils.json_to_sheet(response.trainers);
        var wopts: XLSX.WritingOptions = { bookType: 'xlsx', bookSST: false, type: 'binary' };
        wb.SheetNames.push('Trainer List');
        wb.Sheets['Trainer List'] = ws;
        var wbout = XLSX.write(wb, wopts);
        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "Trainer Emails " + this.nombreConcurso + ".xlsx");
      },
      error => {
        console.log(error);
      }
    );
  }

  public async updateSuspendedMembershipPeople() {
    $('#loader-usef').show();
    const response = await firstValueFrom(this.reporteService.updateSuspendedMembershipPeople(this.idConcurso+ ""));
    $('#loader-usef').hide();
  }

  public async updateUSJASuspendedMembershipPeople() {
    //$('#loader').show();
    this.loading = true;

    $('#loadingmodal').modal('show');
    this.USHJAIds = [];
    this.items = [];
    const response = await firstValueFrom(this.reporteService.getUSJASuspendedMembershipPeople(this.idConcurso));
    let USEFIds = response.USEFId;
    this.totalItems = USEFIds.length;
    //TODO: Cambiar por personas
    const USHJAStatus = (await this.usefService.getUSHJApersonSuspensionInfoBulk(USEFIds.map(u => u.USEFId||''))).map(c => ({
      ushja_suspended: c.ushja_suspended||1,
      ushja_suspension_reason: c.ushja_suspension_reason||'',
      ushja_suspension_status: c.ushja_suspension_status||'',
      ushja_id: c.ushjaId||'',
      usef_id: c.usefId||'',
      id_persona: c.id_caballo||'',
      id_concurso: this.idConcurso
    }));
    $('#loader').hide();
    $('#loadingmodal').modal('hide');
    this.loading = false;
  }

  public async updateUSJASuspendedMembershipHorses() {
    //$('#loader').show();
    this.loading = true;
    $('#loadingmodal').modal('show');
    this.USHJAIds = [];
    this.items = [];

    const response = await firstValueFrom(this.reporteService.getUSJASuspendedMembershipHorses(this.idConcurso));
    const USEFIds = response.USEFId;
    this.totalItems = USEFIds.length;
    const USHJAStatus = (await this.usefService.getUSHJAHorseSuspensionInfoBulk(USEFIds.map(u => u.USEFId||''))).map(c => ({
      ushja_suspended: c.ushja_suspended||1,
      ushja_suspension_reason: c.ushja_suspension_reason||'',
      ushja_suspension_status: c.ushja_suspension_status||'',
      ushja_id: c.ushjaId||'',
      usef_id: c.usefId||'',
      id_caballo: c.id_caballo||'',
      id_concurso: this.idConcurso
    }));

    $('#loader').hide();
    $('#loadingmodal').modal('hide');
    this.loading = false;

    /*await firstValueFrom(this.reporteService.setUSHJAHorseSuspensionInfoBulk(this.idConcurso,USHJAStatus)).then(
      res =>{
        $('#loader').hide();
        $('#loadingmodal').modal('hide');
        console.log(res)
        this.loading = false;
      }
    )*/
  }


  public async updateSuspendedMembershipHorses() {
    $('#loader-usef').show();
    const response = await this.reporteService.updateSuspendedMembershipHorses(this.idConcurso).toPromise();
    console.log(response);
    $('#loader-usef').hide();
  }

}
