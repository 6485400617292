import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ConcursoService } from '../services/concurso.service';
import { EntriesService } from '../services/entries.service';
import { PersonaService } from '../services/persona.service';
import { CaballoService } from '../services/caballo.service';
import { Router } from '@angular/router';
import { PaisService } from '../services/pais.service';
declare var $: any;

@Component({
  selector: 'app-fei-entries',
  templateUrl: './fei-entries.component.html',
  styleUrls: ['./fei-entries.component.css']
})
export class FeiEntriesComponent implements OnInit, AfterViewInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public concursos: any[];
  public entries: any[];
  public showEntries: boolean;
  public jinetes: any[];
  public validado: boolean;
  public saving: boolean;
  public error: boolean;
  public jinetesFaltantes: any[];
  public caballosFaltantes: any[];
  public errores: any[];
  public paises: any[];

  constructor(
    private authService: AuthService,
    private concursoService: ConcursoService,
    private entriesService: EntriesService,
    private router: Router,
    private personaService: PersonaService,
    private caballoService: CaballoService,
    private paisService: PaisService
  ) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.concursos = [];
    this.entries = [];
    this.showEntries = false;
    this.jinetes = [];
    this.validado = false;
    this.saving = false;
    this.error = false;
    this.jinetesFaltantes = [];
    this.caballosFaltantes = [];
    this.errores = [];
    this.paises = [];
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getPaises();
    this.getShowInfo();
  }

  ngAfterViewInit(): void {
    $("#progressbarwizard").bootstrapWizard({
      onTabShow: function (t, r, a) {
        var o = (a + 1) / r.find("li").length * 100;
        $("#progressbarwizard").find(".bar").css({ width: o + "%" })
      }
    });
  }

  private getShowInfo(): void {
    this.concursoService.getConcurso(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          if (response.concurso.idOficial) {
            this.getPruebasFei(response.concurso.idOficial);
            // const index = this.concursos.findIndex(c => c.show_code == response.concurso.idOficial);
            // if (index > -1) {
            //   this.getPruebasFei(response.concurso.idOficial);
            //   const show = this.concursos[index];
            //   this.concursoService.saveFeiShow(show, this.idConcurso).toPromise().catch(reason => {
            //     $.NotificationApp.send("Error", reason.error, 'bottom-right', '#fa5c7c', 'error');
            //     console.log(reason.error);
            //     $('#loader-fei').hide();
            //   });
            // } else {
            //   this.error = true;
            //   $('#loader-fei').hide();
            // }
          } else {
            this.error = true;
            $('#loader-fei').hide();
          }
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader-fei').hide();
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It was not possible to consult the show info.', 'bottom-right', '#fa5c7c', 'error');
        $('#loader-fei').hide();
      }
    );
  }

  public getPaises(): void {
    this.paisService.getPaises().subscribe(
      response => {
        this.paises = Array.from(response.paises);
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It was not possible to consult the countries list.', 'bottom-right', '#fa5c7c', 'error');
      }
    );
  }

  private getConcursosFei(): void {
    $('#loader-fei').show();
    this.concursoService.getConcursosFei().subscribe(
      response => {
        //console.log(response)
        this.concursos = response;
        this.getShowInfo();
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It was not possible to consult the FEI shows list.', 'bottom-right', '#fa5c7c', 'error');
        $('#loader-fei').hide();
      }
    );
  }

  public getPruebasFei(idConcurso): void {
    this.showEntries = true;
    this.entries = [];
    //$('#loader-fei').show();
    this.entriesService.getEntriesFei(idConcurso).subscribe(
      response => {
        $('#loader-fei').hide();
        // this.jinetes = response.map(e => e.rider);
        for (const entry of response) {
          for (const horse of entry.horses) {
            if (horse.status == 'H-ACCEP' && horse.horsename) {
              this.entries.push({
                horse,
                rider: entry.rider,
                status: horse.status,
                event: entry.event
              });
              this.jinetes.push({
                name: entry.rider.name,
                lastname: entry.rider.lastname,
                birthdate: entry.rider.birthdate,
                country: entry.rider.country,
                fei: entry.rider.fei
              })
            }
          }
        }
        let element: HTMLElement = document.getElementById('step2') as HTMLElement;
        element.click();
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It was not possible to consult the entries list.', 'bottom-right', '#fa5c7c', 'error');
        $('#loader-fei').hide();
      }
    );
  }

  public async validate() {
    $('#loader-fei').show();
    this.errores = [];
    const caballos = this.entries.map(e => e.horse);
    this.entriesService.validarJinetesyCaballos(this.jinetes, caballos, this.idConcurso).toPromise().then(
      async response => {
        if (!response.error) {
          this.jinetesFaltantes = response.jinetesFaltantes;
          this.caballosFaltantes = response.caballosFaltantes;
          //Crear jinetes y caballos faltantes
          const jinetesPromises = this.jinetesFaltantes.map(j => this.createRider(j));
          const caballosPromises = this.caballosFaltantes.map(c => this.createHorse(c));
          await Promise.all([...jinetesPromises, ...caballosPromises]).then(values => {
            this.errores = values.filter((v: any) => v.error).map((v: any) => v.message);
            const jinetesAgregados = values.filter((v: any) => !v.error && v.riderFei).map((v: any) => v.riderFei);
            const caballosAgregados = values.filter((v: any) => !v.error && v.horseFei).map((v: any) => v.horseFei);
            console.log('jinetesAgregados', jinetesAgregados);
            console.log('caballosAgregados', caballosAgregados);
            //Quitar los jinetes y caballos recien agregados de los faltantes
            this.jinetesFaltantes = this.jinetesFaltantes.filter(j => !jinetesAgregados.includes(j));
            this.caballosFaltantes = this.caballosFaltantes.filter(c => !caballosAgregados.includes(c));
            console.log('values', values);
          },
            reason => {
              console.log('reason', reason);
              $.NotificationApp.send("Error", reason, 'bottom-right', '#fa5c7c', 'error');
            });
          if (this.errores.length > 0) {
            $.NotificationApp.send("Error", this.errores, 'bottom-right', '#fa5c7c', 'error', 20000);
          }
          console.log(this.errores);
          if (this.jinetesFaltantes.length == 0 && this.caballosFaltantes.length == 0) {
            this.validado = true;
          }

          $('#loader-fei').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader-fei').hide();
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It was not possible to validate the riders and horses.', 'bottom-right', '#fa5c7c', 'error');
        $('#loader-fei').hide();
      }
    );
  }

  public saveEntries(): void {
    this.saving = true;
    $('#loader').show();
    this.entriesService.saveEntries(this.entries, this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          let element: HTMLElement = document.getElementById('step3') as HTMLElement;
          element.click();
          $('#loader').hide();
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader').hide();
        }
      },
      error => {
        console.log(error);
        $.NotificationApp.send("Error", 'It was not possible to save the entries.', 'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
      }
    );
  }

  public async createRider(fei: string) {
    console.log('createRider', fei);
    return new Promise(async (resolve, reject) => {
      //Consultar datos fei
      const response = await this.personaService.getFeiInfo(fei).toPromise();
      if (response.error) {
        resolve({ error: true, message: `${fei}: ${response.message}` });
      }
      const persona = response.persona;

      //console.log(persona);
      console.log('Persona: ', persona);

      // Match persona.country_of_birth with paises.siglas_pais
      const countryOfBirth = Array.from(this.paises).find(p => p.acronym == persona.countryOfBirth)?.id;

      console.log("Country of Birth Match: ", countryOfBirth);

      const person = {
        name: persona.name || '',
        lastname: persona.lastname || '',
        fei: fei || '',
        gender: persona.gender || '',
        entityType: "I",
        dateOfBirth: persona.dateOfBirth || '',
        nationality: countryOfBirth || '',
      }

      resolve({ error: false, riderFei: fei });
      // Add person
      const responseAddPerson = await this.personaService.addPersona(person, this.idConcurso, this.idUsuario).toPromise();
      if (responseAddPerson.error) {
        resolve({ error: true, message: `${fei}: ${responseAddPerson.message}` });
      }
      resolve({ error: false, riderFei: fei });

    });
  }

  public async createHorse(fei: string) {
    return new Promise(async (resolve, reject) => {
      //Consultar datos fei
      const response = await this.caballoService.getFeiInfo(fei).toPromise();
      if (response.error) {
        resolve({ error: true, message: `${fei}: ${response.message}` });
      }
      const caballo = response.caballo;
      //TODO: mapear campos para enviar al api de crear
      const horse = {
        name: caballo.name||'',
        fei: fei,
        country: caballo.countryOfOrigin||'',
        chip: caballo.chipId||'',
        owner: caballo.ownerId ||'',
        color: caballo.color||'',
        colorComplement: caballo.color_complement||'',
        breed: caballo.breed||'',
        gender: caballo.gender||'',
        dateOfBirth: caballo.dateOfBirth||'',
        sire: caballo.sire||'',
        dam: caballo.dam||'',
        usef: caballo.usef||'',
        suspensionStatus: caballo.suspensionStatus||'',
        height: caballo.height||'',
        height_category: caballo.height_category||'',
        notes: caballo.notes||'',
      };

      console.log('Horse: ', horse);

      // Save Horses
      const responseAddHorse = await this.caballoService.addCaballo(horse, this.idConcurso, this.idUsuario).toPromise();
      if (responseAddHorse.error) {
        resolve({ error: true, message: `${fei}: ${responseAddHorse.message}` });
      }

      resolve({ error: false, caballo });
    });
  }
}
